import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import EditApplicationForm from "./EditApplicationForm";
import { useStore } from "../../store";
import APIService from "../../APIService";
import { useParams } from "react-router-dom";

function EditApplicationPage() {
  const setLoaderVisible = useStore((state) => state.setLoaderVisible);
  const setApplication = useStore((state) => state.setApplication);
  const setProducts = useStore((state) => state.setProducts);

  const { id } = useParams();

  useEffect(() => {
    const fetchApplicationAndProducts = async () => {
      setLoaderVisible(true);

      const [application, products] = await Promise.all([
        APIService.getApplication(id || ""),
        APIService.getProductsForApplication(id || ""),
      ]);

      setApplication(application);
      setProducts(products);

      setLoaderVisible(false);
    };

    fetchApplicationAndProducts();
  }, [id, setApplication, setProducts, setLoaderVisible]);

  return (
    <Layout title="Review your application">
      <EditApplicationForm />
    </Layout>
  );
}

export default EditApplicationPage;
