import { Alert } from "react-bootstrap";
import React from "react";
import { useFormikContext } from "formik";

function FormHasErrorsAlert() {
  const { errors, touched } = useFormikContext();

  const errorFields = Object.keys(errors);
  const touchedFields = Object.keys(touched);

  const hasErrors =
    errorFields.filter((value) => touchedFields.includes(value)).length > 0;

  return (
    <Alert variant="danger" show={hasErrors}>
      Please make sure that all form fields are completed correctly.
    </Alert>
  );
}

export default FormHasErrorsAlert;
