import { Form } from "react-bootstrap";
import React from "react";
import { Field } from "formik";
import { FormikFieldProps } from "../types";

export type Option = {
  value: string;
  label: string;
};

type Props = {
  name: string;
  label: string;
  options: Option[];
};

function Select({ name, label, options }: Props) {
  return (
    <Field name={name}>
      {({ field, meta }: FormikFieldProps) => (
        <>
          <Form.Label>{label}</Form.Label>
          <Form.Select
            name={name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            isInvalid={!!meta.error}
          >
            {options.map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {meta.error}
          </Form.Control.Feedback>
        </>
      )}
    </Field>
  );
}

export default Select;
