import { Form } from "react-bootstrap";
import React from "react";
import { Field } from "formik";
import { FormikFieldProps } from "../types";
import FieldError from "./FieldError";

type Props = {
  name: string;
  label: string;
};

function SingleCheckbox({ name, label }: Props) {
  return (
    <Field name={name}>
      {({ field, meta, form }: FormikFieldProps) => {
        const onChange = async (...args: any) => {
          // This is necessary to prevent wonky validation

          // @ts-ignore
          await field.onChange(...args);

          await form.validateField(name);
        };

        return (
          <>
            <Form.Check
              inline
              name={name}
              label={label}
              checked={meta.value}
              onChange={onChange}
              onBlur={field.onBlur}
            />
            {meta.error && meta.touched && <FieldError error={meta.error} />}
          </>
        );
      }}
    </Field>
  );
}

export default SingleCheckbox;
