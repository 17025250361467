import { parsePhoneNumberFromString } from "libphonenumber-js";
import * as Yup from "yup";
import { TestContext } from "yup";

const isValidPhoneNumber = (value: string): boolean => {
  if (!value) {
    return false;
  }

  try {
    const parsed = parsePhoneNumberFromString(value, "ZA");

    return parsed?.isValid() || false;
  } catch (error) {
    return false;
  }
};

const validatePhoneNumber = (args: any) => {
  return (Yup as any)
    .string()
    .test(
      "is-phone-number",
      args,
      function test(this: TestContext, value: any) {
        if (value) {
          if (isValidPhoneNumber(value)) {
            return true;
          }
          return this.createError({
            // eslint-disable-next-line no-template-curly-in-string
            message: "${path} is not a valid phone number.",
          });
        }
        return this.createError({
          // eslint-disable-next-line no-template-curly-in-string
          message: "${path} field is required.",
        });
      }
    );
};

const validator = {
  isValidPhoneNumber,
  validatePhoneNumber,
};

export default validator;
