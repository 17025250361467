import create from "zustand";
import { Application, Product } from "./types";

export interface State {
  isLoaderVisible: boolean;
  setLoaderVisible: (isLoaderVisible: boolean) => void;
  application: Application | null;
  setApplication: (application: Application) => void;
  products: Product[];
  setProducts: (products: Product[]) => void;
}

export const useStore = create<State>((set) => ({
  isLoaderVisible: false,
  setLoaderVisible: (isLoaderVisible) => set({ isLoaderVisible }),
  application: null,
  setApplication: (application) => set({ application }),
  products: [],
  setProducts: (products) => set({ products }),
}));
