import axios from "axios";
import { Application, Product } from "./types";
import CONFIG from "./config";

const client = axios.create({
  baseURL: CONFIG.apiURL,
  responseType: "json",
  headers: {
    "Content-type": "application/json",
  },
  timeout: 12000,
});

const getApplication = async (id: string): Promise<Application> => {
  const response = await client.get(`/v1/applications/${id}`);
  return response.data;
};

const updateApplication = async (
  id: string,
  payload: Record<string, unknown>
): Promise<Application> => {
  const response = await client.patch(`/v1/applications/${id}`, payload);
  return response.data;
};

const getProductsForApplication = async (id: string): Promise<Product[]> => {
  const response = await client.get(`/v1/applications/${id}/products`);
  return response.data;
};

const setProductsForApplication = async (
  id: string,
  products: Record<string, unknown>
): Promise<Product[]> => {
  const response = await client.put(
    `/v1/applications/${id}/products`,
    products
  );
  return response.data;
};

const service = {
  getApplication,
  getProductsForApplication,
  updateApplication,
  setProductsForApplication,
};

export default service;
