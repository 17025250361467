import React from "react";
import FormRow from "../../components/FormRow";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/Input";
import Select, { Option } from "../../components/Select";
import { Field } from "formik";
import { Form } from "react-bootstrap";
import { Bank, FormikFieldProps } from "../../types";
import FieldCollection from "../../components/FieldCollection";

function BankAccountFields() {
  const banks: Option[] = Object.values(Bank).map((v) => ({
    value: v,
    label: v,
  }));

  return (
    <FieldCollection title="Bank Account">
      <FormRow>
        <FormGroup controlId="formBank">
          <Select name="bank_account.bank" label="Bank *" options={banks} />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup controlId="formBankAccountHolderName">
          <Input
            name="bank_account.account_holder_name"
            label="Bank Account Holder Name *"
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup controlId="formBankAccountNumber">
          <Input
            type="tel"
            name="bank_account.account_number"
            label="Bank Account Number *"
          />
        </FormGroup>
      </FormRow>
      <FormRow last>
        <FormGroup>
          <Field name="bank_account.account_type">
            {({ field, meta }: FormikFieldProps) => (
              <>
                <Form.Label>Bank Account Type</Form.Label>
                <Form.Check
                  id="formBankAccountTypeCheque"
                  type="radio"
                  name="bank_account.account_type"
                  label="Cheque"
                  value="Cheque"
                  checked={meta.value === "Cheque"}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
                <Form.Check
                  id="formBankAccountTypeSavings"
                  type="radio"
                  name="bank_account.account_type"
                  label="Savings"
                  value="Savings"
                  checked={meta.value === "Savings"}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              </>
            )}
          </Field>
        </FormGroup>
      </FormRow>
    </FieldCollection>
  );
}

export default BankAccountFields;
