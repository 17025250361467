import React from "react";
import FormRow from "../../components/FormRow";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/Input";
import Select, { Option } from "../../components/Select";
import FieldCollection from "../../components/FieldCollection";
import { Province } from "../../types";

function PostalAddressFields() {
  const provinces: Option[] = Object.values(Province).map((v) => ({
    value: v,
    label: v,
  }));

  return (
    <FieldCollection title="Postal Address">
      <FormRow>
        <FormGroup controlId="formPostalAddressLine1">
          <Input name="postal_address.line1" label="Street Address *" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup controlId="formPostalAddressLine2">
          <Input
            name="postal_address.line2"
            label="Apartment Number & Complex Name"
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup controlId="formPostalAddressSuburb">
          <Input name="postal_address.suburb" label="Suburb *" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup controlId="formPostalAddressCity">
          <Input name="postal_address.city" label="City *" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup controlId="formPostalAddressProvince">
          <Select
            name="postal_address.province"
            label="Province *"
            options={provinces}
          />
        </FormGroup>
      </FormRow>
      <FormRow last>
        <FormGroup controlId="formPostalCode">
          <Input
            type="tel"
            name="postal_address.postal_code"
            label="Postal Code *"
          />
        </FormGroup>
      </FormRow>
    </FieldCollection>
  );
}

export default PostalAddressFields;
