import React from "react";
import { Field } from "formik";
import { Button } from "react-bootstrap";
import Input from "../../components/Input";
import ProductStatusBadge from "./ProductStatusBadge";
import { Product, ProductStatus } from "../../types";

type Props = {
  index: number;
  product: Product;
  onClickRemove?: (index: number, product: Product) => void;
  showLabelsColumn?: boolean;
};

function ProductRow({
  index,
  product,
  onClickRemove: onClickRemoveCallback,
  showLabelsColumn = true,
}: Props) {
  const onClickRemove = () => {
    if (onClickRemoveCallback) {
      onClickRemoveCallback(index, product);
    }
  };

  return (
    <Field name={`products.${index}`}>
      {() => (
        <tr key={index}>
          <td>{index + 1}.</td>
          <td>
            <Input
              name={`products.${index}.name`}
              readOnly={
                product.status === ProductStatus.APPROVED ||
                product.status === ProductStatus.REJECTED
              }
            />
          </td>
          <td>
            <Input
              type="number"
              step="1"
              min="1"
              name={`products.${index}.price`}
              readOnly={product.status === ProductStatus.REJECTED}
              prepend="R"
            />
          </td>
          <td>
            <Input
              name={`products.${index}.barcode`}
              readOnly={product.status === ProductStatus.REJECTED}
            />
          </td>
          {showLabelsColumn && (
            <td>
              <Input
                type="number"
                step="1"
                min="0"
                name={`products.${index}.labels`}
                readOnly={product.status === ProductStatus.REJECTED}
              />
            </td>
          )}
          <td className="align-middle">
            <ProductStatusBadge status={product.status} />
          </td>
          <td className="align-middle text-end">
            {product.status === ProductStatus.PENDING_APPROVAL ||
            product.status === ProductStatus.APPROVED ? (
              <Button variant="danger" onClick={onClickRemove}>
                <i className="bi-trash"></i>
              </Button>
            ) : null}
          </td>
        </tr>
      )}
    </Field>
  );
}

export default ProductRow;
