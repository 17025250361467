import React from "react";

type Props = {
  error?: string;
};

function FieldError({ error }: Props) {
  return error ? (
    <div className="invalid-feedback d-block">{error}</div>
  ) : (
    <></>
  );
}

export default FieldError;
