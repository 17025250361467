import "bootstrap/dist/css/bootstrap.min.css";
import "react-block-ui/style.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import React from "react";
import "./App.css";
import EditApplicationPage from "./pages/EditApplicationPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import * as Sentry from "@sentry/react";
import Validator from "./Validator";
import * as Yup from "yup";

Yup.addMethod(Yup.string, "phone", Validator.validatePhoneNumber);

function App() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <Sentry.ErrorBoundary>
      <BrowserRouter>
        <SentryRoutes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/applications/:id/edit"
            element={<EditApplicationPage />}
          />
        </SentryRoutes>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
}

export default App;
