import React from "react";
import { Card } from "react-bootstrap";

type Props = {
  title: string;
  children: React.ReactNode;
};

function FieldCollection({ title, children }: Props) {
  return (
    <Card bg="light" className="mb-4">
      <Card.Header>
        <h5 className="mb-0">{title}</h5>
      </Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
}

export default FieldCollection;
