import React from "react";
import FormRow from "../../components/FormRow";
import FormGroup from "../../components/FormGroup";
import Switch from "../../components/Switch";
import FieldCollection from "../../components/FieldCollection";
import Select, { Option } from "../../components/Select";
import {
  BarcodeCollectionOrCourier,
  BarcodeNumbersAndPrinting,
  ProductCategory,
} from "../../types";
import { useFormikContext } from "formik";
import { isPrintingRequired } from "../../utils";

function MarketExperienceFields() {
  const barcodeNumbersAndPrintingOptions: Option[] = Object.values(
    BarcodeNumbersAndPrinting
  ).map((v) => ({
    value: v,
    label: v,
  }));
  const barcodeCollectionOrCourierOptions: Option[] = Object.values(
    BarcodeCollectionOrCourier
  ).map((v) => ({
    value: v,
    label: v,
  }));
  const productCategories: Option[] = Object.values(ProductCategory).map(
    (v) => ({
      value: v,
      label: v,
    })
  );

  const formik = useFormikContext();
  const values = formik.values as any;

  const showBarcodeCollectionOrCourier = isPrintingRequired(
    values.market_experience.barcode_numbers_and_printing
  );

  return (
    <FieldCollection title="Market Experience">
      <FormRow>
        <FormGroup controlId="formRequiresElectricity">
          <Switch
            name="market_experience.requires_electricity"
            label="Do you require electricity?"
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup controlId="formBarcodeNumbersAndPrinting">
          <Select
            name="market_experience.barcode_numbers_and_printing"
            label="Barcode numbers & printing *"
            options={barcodeNumbersAndPrintingOptions}
          />
        </FormGroup>
      </FormRow>
      {showBarcodeCollectionOrCourier && (
        <FormRow>
          <FormGroup controlId="formBarcodeCollectionOrCourier">
            <Select
              name="market_experience.barcode_collection_or_courier"
              label="Barcode collection or courier *"
              options={barcodeCollectionOrCourierOptions}
            />
          </FormGroup>
        </FormRow>
      )}
      <FormRow>
        <FormGroup controlId="formProductCategory">
          <Select
            name="market_experience.product_category"
            label="Product Category *"
            options={productCategories}
          />
        </FormGroup>
      </FormRow>
    </FieldCollection>
  );
}

export default MarketExperienceFields;
