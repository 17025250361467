import React from "react";
import FormRow from "../../components/FormRow";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/Input";
import FieldCollection from "../../components/FieldCollection";

function PersonalInformationFields() {
  return (
    <FieldCollection title="Personal Information">
      <FormRow>
        <FormGroup controlId="formFirstName">
          <Input name="first_name" label="First Name *" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup controlId="formLastName">
          <Input name="last_name" label="Last Name *" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup controlId="formEmail">
          <Input type="email" name="email" label="Email *" />
        </FormGroup>
      </FormRow>
      <FormRow last>
        <FormGroup controlId="formMobileNumber">
          <Input type="tel" name="mobile_number" label="Mobile Number *" />
        </FormGroup>
      </FormRow>
    </FieldCollection>
  );
}

export default PersonalInformationFields;
