import React from "react";
import { Row } from "react-bootstrap";

type Props = {
  last?: boolean;
  children: React.ReactNode;
};

function FormRow({ last = false, children }: Props) {
  return <Row className={last ? "mb-0" : "mb-3"}>{children}</Row>;
}

export default FormRow;
