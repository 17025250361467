import React from "react";
import { ProductStatus } from "../../types";
import { Badge } from "react-bootstrap";

type Props = {
  status: ProductStatus;
};

const getBadgeVariant = (status: ProductStatus): string => {
  switch (status) {
    case ProductStatus.PENDING_APPROVAL:
      return "warning";
    case ProductStatus.APPROVED:
      return "success";
    case ProductStatus.REJECTED:
      return "danger";
    default:
      return "light";
  }
};

function ProductStatusBadge({ status }: Props) {
  return <Badge bg={getBadgeVariant(status)}>{status}</Badge>;
}

export default ProductStatusBadge;
