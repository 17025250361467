import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Formik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";
import PersonalInformationFields from "./PersonalInformationFields";
import PostalAddressFields from "./PostalAddressFields";
import BankAccountFields from "./BankAccountFields";
import MarketExperienceFields from "./MarketExperienceFields";
import ProductManager, { toFormikFormValues } from "./ProductManager";
import { useStore } from "../../store";
import FormHasErrorsAlert from "../../components/FormHasErrorsAlert";
import APIService from "../../APIService";
import toast from "react-hot-toast";
import FormRow from "../../components/FormRow";
import FormGroup from "../../components/FormGroup";
import SingleCheckbox from "../../components/SingleCheckbox";
import { ProductStatus } from "../../types";
import { isPrintingRequired } from "../../utils";

function EditApplicationForm() {
  const application = useStore((state) => state.application);
  const products = useStore((state) => state.products);
  const setLoaderVisible = useStore((state) => state.setLoaderVisible);
  const setApplication = useStore((state) => state.setApplication);
  const setProducts = useStore((state) => state.setProducts);

  const buildProductsSchema = () => {
    // We build a dynamic schema for the array of products because we want to perform conditional validation.
    // We only want to make the nested "labels" value required if the outer "requires_printed_barcodes" is true.
    // To do this, we need to use when() to dynamically build the schema.

    const nameRule = Yup.string().required().label("Name");
    const priceRule = Yup.number()
      .required()
      .integer("Price must be a whole number")
      .min(1)
      .label("Price");
    const barcodeRule = Yup.string().label("Barcode");
    const labelsRule = Yup.number()
      .integer("Labels must be a whole number")
      .label("Labels");

    return Yup.array().when("market_experience.barcode_numbers_and_printing", {
      is: isPrintingRequired,
      then: Yup.array().of(
        Yup.object().shape({
          name: nameRule,
          price: priceRule,
          barcode: barcodeRule,
          labels: labelsRule.when("status", {
            is: (value: any) => {
              return (
                value === ProductStatus.PENDING_APPROVAL ||
                value === ProductStatus.APPROVED
              );
            },
            then: labelsRule.required().min(1),
          }),
        })
      ),
      otherwise: Yup.array().of(
        Yup.object().shape({
          name: nameRule,
          price: priceRule,
          barcode: barcodeRule,
          labels: labelsRule,
        })
      ),
    });
  };

  const schema = Yup.object().shape({
    first_name: Yup.string().required().label("First Name"),
    last_name: Yup.string().required().label("Last Name"),
    email: Yup.string().email().required().label("Email"),
    mobile_number: (Yup as any)
      .string()
      .required()
      .phone()
      .label("Mobile Number"),
    postal_address: Yup.object().shape({
      line1: Yup.string().required().label("Street Address"),
      line2: Yup.string().label("Apartment Number & Complex Name"),
      suburb: Yup.string().required().label("Suburb"),
      city: Yup.string().required().label("City"),
      province: Yup.string().required().label("Province"),
      postal_code: Yup.string()
        .required()
        .matches(/^\d{4}$/, "Postal Code must be a valid 4 digit number")
        .label("Postal Code"),
    }),
    bank_account: Yup.object().shape({
      bank: Yup.string().required().label("Bank"),
      account_holder_name: Yup.string().required().label("Account Holder Name"),
      account_number: Yup.string()
        .required()
        .matches(/^\d+$/, "Account Number may only contain numbers")
        .label("Account Number"),
      account_type: Yup.string().required().label("Account Type"),
    }),
    market_experience: Yup.object().shape({
      requires_electricity: Yup.boolean().label("Requires Electricity"),
      barcode_numbers_and_printing: Yup.string()
        .required()
        .label("Barcode numbers & printing"),
      barcode_collection_or_courier: Yup.string()
        .label("Barcode collection or courier")
        .when("barcode_numbers_and_printing", {
          is: isPrintingRequired,
          then: Yup.string().required(),
        }),
      product_category: Yup.string().required().label("Product Category"),
    }),
    products: buildProductsSchema(),
    have_confirmed_application: Yup.boolean()
      .oneOf([true], "You are required to check this checkbox.")
      .label("Confirmed Application"),
    agree_with_terms_and_conditions: Yup.boolean()
      .oneOf([true], "You are required to check this checkbox.")
      .label("Terms & Conditions"),
  });

  const initialValues = {
    first_name: application?.first_name || "",
    last_name: application?.last_name || "",
    email: application?.email || "",
    mobile_number: application?.mobile_number || "",
    postal_address: {
      line1: application?.postal_address.line1 || "",
      line2: application?.postal_address.line2 || "",
      suburb: application?.postal_address.suburb || "",
      city: application?.postal_address.city || "",
      province: application?.postal_address.province || "",
      postal_code: application?.postal_address.postal_code || "",
    },
    bank_account: {
      bank: application?.bank_account.bank || "",
      account_holder_name: application?.bank_account.account_holder_name || "",
      account_number: application?.bank_account.account_number || "",
      account_type: application?.bank_account.account_type || "",
    },
    market_experience: {
      requires_electricity:
        application?.market_experience.requires_electricity || false,
      barcode_numbers_and_printing:
        application?.market_experience.barcode_numbers_and_printing || "",
      barcode_collection_or_courier:
        application?.market_experience.barcode_collection_or_courier || "",
      product_category: application?.market_experience.product_category || "",
    },
    products: toFormikFormValues(products),
    have_confirmed_application: false,
    agree_with_terms_and_conditions: false,
  };

  const onSubmit = async (
    values: FormikValues,
    { setSubmitting, setFieldValue }: FormikHelpers<any>
  ) => {
    setLoaderVisible(true);

    const newValues = { ...values };
    delete newValues["products"];
    const products = values["products"];

    const id = application?.id || "";

    const [updatedApplication, updatedProducts] = await Promise.all([
      APIService.updateApplication(id, newValues),
      APIService.setProductsForApplication(id, products),
    ]);

    // There's a bug where the ProductManager (Formik FieldArray) is not re-rendering with the correct values
    // when the state of products is updated with the setProducts() call below.
    // Whilst the EditApplicationForm re-renders and Formik's initialValues are updated, the ProductManager doesn't
    // seem to use these values.
    // As a work-around, we manually call the setFieldValue() to set the products to the updated value we got back
    // from the API.
    setFieldValue("products", toFormikFormValues(updatedProducts));

    setApplication(updatedApplication);
    setProducts(updatedProducts);
    setSubmitting(false);
    setLoaderVisible(false);

    toast.success("Your application has been updated.", {
      duration: 8000,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <PersonalInformationFields />
          <PostalAddressFields />
          <BankAccountFields />
          <MarketExperienceFields />
          <ProductManager />
          <hr />
          <FormRow>
            <FormGroup controlId="formHaveConfirmedApplication">
              <SingleCheckbox
                name="have_confirmed_application"
                label="I confirm that the information on my application is correct."
              />
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup controlId="formAgreeWithTermsAndConditions">
              <SingleCheckbox
                name="agree_with_terms_and_conditions"
                label="I agree with the following terms & conditions"
              />
              <Card body>
                <p>
                  I, the undersigned, indemnify the organisers, their employees
                  &amp; agents against any liability of any nature for any
                  damage I, my business and/or my employees may suffer directly
                  or indirectly because of theft, breakage, injury, fire,
                  lightning, vis major casus fortuitous or any other cause.{" "}
                </p>
                <p className="mb-0">
                  I also understand that I am liable for any taxes that may
                  occur from the sale of my goods at Simply Gifts Market. The
                  organisers of Simply Gifts Market only act as hosts of the
                  venue from where I may sell my goods from.
                </p>
              </Card>
            </FormGroup>
          </FormRow>
          <FormHasErrorsAlert />
          <Button type="submit" size="lg" disabled={isSubmitting}>
            Save application
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default EditApplicationForm;
