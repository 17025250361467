import { Form } from "react-bootstrap";
import React from "react";
import { Field } from "formik";
import { FormikFieldProps } from "../types";
import FieldError from "./FieldError";

type Props = {
  name: string;
  label: string;
};

function Switch({ name, label }: Props) {
  return (
    <Field name={name}>
      {({ field, meta }: FormikFieldProps) => (
        <>
          <Form.Label>{label}</Form.Label>
          <Form.Check
            type="switch"
            name={name}
            checked={meta.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
          />
          {meta.error && meta.touched && <FieldError error={meta.error} />}
        </>
      )}
    </Field>
  );
}

export default Switch;
