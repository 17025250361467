import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import React from "react";
import { Field, useField } from "formik";
import { FormikFieldProps } from "../types";
import FieldError from "./FieldError";

type Props = {
  name: string;
  label?: string;
  type?: string;
  placeholder?: string;
  floatingLabel?: boolean;
  controlId?: string;
  size?: "sm" | "lg";
  readOnly?: boolean;
  prepend?: string;
  [x: string]: any;
};

type FormControlProps = {
  name: string;
  label?: string;
  type?: string;
  placeholder?: string;
  size?: "sm" | "lg";
  readOnly?: boolean;
  [x: string]: any;
};

function FormControl({
  name,
  label,
  type = "text",
  placeholder,
  size,
  readOnly = false,
  ...props
}: FormControlProps) {
  const [field, meta] = useField(name);

  return (
    <Form.Control
      type={type}
      name={name}
      size={size}
      placeholder={placeholder || label}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      isInvalid={!!meta.error}
      readOnly={readOnly}
      {...props}
    />
  );
}

function Input(props: Props) {
  const { name, label, floatingLabel = false, controlId, prepend } = props;

  return (
    <Field name={name}>
      {({ meta }: FormikFieldProps) => {
        if (floatingLabel && prepend) {
          return (
            <>
              <InputGroup>
                <InputGroup.Text>{prepend}</InputGroup.Text>
                <FloatingLabel controlId={controlId} label={label}>
                  <FormControl {...props} />
                </FloatingLabel>
              </InputGroup>
              {meta.error && meta.touched && <FieldError error={meta.error} />}
            </>
          );
        } else if (floatingLabel) {
          return (
            <FloatingLabel controlId={controlId} label={label}>
              <FormControl {...props} />
              {meta.error && meta.touched && <FieldError error={meta.error} />}
            </FloatingLabel>
          );
        } else if (prepend) {
          return (
            <>
              {label && <Form.Label>{label}</Form.Label>}
              <InputGroup>
                <InputGroup.Text>{prepend}</InputGroup.Text>
                <FormControl {...props} />
              </InputGroup>
              {meta.error && meta.touched && <FieldError error={meta.error} />}
            </>
          );
        } else {
          return (
            <>
              {label && <Form.Label>{label}</Form.Label>}
              <FormControl {...props} />
              {meta.error && meta.touched && <FieldError error={meta.error} />}
            </>
          );
        }
      }}
    </Field>
  );
}

export default Input;
