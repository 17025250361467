import React from "react";
import BlockUi from "react-block-ui";
import "./Loader.css";
import { useStore } from "../store";

type Props = {
  children: React.ReactNode;
};

function AnimatedSpinner() {
  return (
    <div className="loadingio-spinner-dual-ring-ikz7yluk4bf">
      <div className="ldio-inndqbdhqrk">
        <div></div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

function Loader({ children }: Props) {
  const isLoaderVisible = useStore((state) => state.isLoaderVisible);

  return (
    <BlockUi tag="div" blocking={isLoaderVisible} loader={<AnimatedSpinner />}>
      {children}
    </BlockUi>
  );
}

export default Loader;
