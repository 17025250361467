import React, { useState } from "react";
import { FieldArray } from "formik";
import ProductRow from "./ProductRow";
import FieldCollection from "../../components/FieldCollection";
import { Button, Table } from "react-bootstrap";
import { Product, ProductStatus } from "../../types";
import RemoveProductModal from "./RemoveProductModal";
import { isPrintingRequired } from "../../utils";

function ProductManager() {
  const [activeProductIndex, setActiveProductIndex] = useState<number | null>(
    null
  );
  const [activeProduct, setActiveProduct] = useState<Product | null>(null);
  const [isRemoveProductModalVisible, setRemoveProductModalVisibility] =
    useState<boolean>(false);

  return (
    <FieldCollection title="Products">
      <FieldArray
        name="products"
        render={({ push, remove, form }) => {
          const showLabelsColumn = isPrintingRequired(
            form.values.market_experience.barcode_numbers_and_printing
          );

          const onClickAddProduct = () => {
            const product: Product = {
              id: "",
              name: "",
              price: "",
              barcode: "",
              labels: "",
              status: ProductStatus.PENDING_APPROVAL,
            };

            push(product);
          };

          const onClickRemoveProduct = (index: number, product: Product) => {
            setActiveProductIndex(index);
            setActiveProduct(product);
            setRemoveProductModalVisibility(true);
          };

          const onClickCancelRemoveProductModal = () => {
            setActiveProductIndex(null);
            setActiveProduct(null);
            setRemoveProductModalVisibility(false);
          };

          const onClickConfirmDeleteInRemoveProductModal = () => {
            remove(activeProductIndex as number);

            setActiveProductIndex(null);
            setActiveProduct(null);
            setRemoveProductModalVisibility(false);
          };

          return (
            <>
              <ul>
                <li>The product name cannot exceed 13 characters.</li>
                <li>
                  The product price must be rounded off to the nearest rand.
                </li>
                <li>
                  If your products are not barcoded, you can leave this blank
                  and we'll generate barcodes on your behalf.
                </li>
                {showLabelsColumn && (
                  <li>
                    If you need us to print your barcodes, please capture the
                    quantity of labels, per product.
                  </li>
                )}
                <li>
                  If your product information is in a Microsoft Excel or CSV
                  format, you may email it to{" "}
                  <a href="mailto:info@simplygiftsmarket.co.za">
                    info@simplygiftsmarket.co.za
                  </a>{" "}
                  for us to capture on your behalf.
                </li>
              </ul>
              <hr />
              {form.values.products && form.values.products.length > 0 && (
                <Table>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Barcode</th>
                      {showLabelsColumn && <th>Labels</th>}
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.values.products.map(
                      (product: Product, index: number) => (
                        <ProductRow
                          key={index}
                          index={index}
                          product={product}
                          onClickRemove={onClickRemoveProduct}
                          showLabelsColumn={showLabelsColumn}
                        />
                      )
                    )}
                  </tbody>
                </Table>
              )}
              <Button variant="secondary" onClick={onClickAddProduct}>
                Add Product
              </Button>
              <RemoveProductModal
                show={isRemoveProductModalVisible}
                product={activeProduct}
                onClickCancel={onClickCancelRemoveProductModal}
                onClickDelete={onClickConfirmDeleteInRemoveProductModal}
              />
            </>
          );
        }}
      />
    </FieldCollection>
  );
}

export const toFormikFormValue = (
  product: Product
): Record<string, unknown> => {
  return {
    id: product.id || "",
    name: product.name || "",
    price: product.price || "",
    barcode: product.barcode || "",
    labels: product.labels || "",
    status: product.status || ProductStatus.PENDING_APPROVAL,
  };
};

export const toFormikFormValues = (
  products: Product[]
): Record<string, unknown>[] => {
  return products.map(toFormikFormValue);
};

export default ProductManager;
