import { FieldInputProps, FieldMetaProps, FormikProps } from "formik";

export type FormikFieldProps = {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  meta: FieldMetaProps<any>;
};

export enum ProductStatus {
  PENDING_APPROVAL = "Pending Approval",
  APPROVED = "Approved",
  REJECTED = "Rejected",
}

export enum Province {
  EASTERN_CAPE = "Eastern Cape",
  FREE_STATE = "Free State",
  GAUTENG = "Gauteng",
  KWAZULU_NATAL = "KwaZulu-Natal",
  LIMPOPO = "Limpopo",
  MPUMALANGA = "Mpumalanga",
  NORTHERN_CAPE = "Northern Cape",
  NORTH_WEST = "North West",
  WESTERN_CAPE = "Western Cape",
}

export enum Bank {
  ABSA = "ABSA",
  BIDVEST = "Bidvest",
  CAPITEC = "Capitec",
  FNB = "FNB",
  INVESTEC = "Investec",
  NEDBANK = "Nedbank",
  STANDARD_BANK = "Standard Bank",
  TYME_BANK = "TymeBank",
}

export enum BarcodeNumbersAndPrinting {
  HAVE_OWN_AND_WILL_PRINT_MYSELF = "I have my own barcodes and I will print them myself",
  HAVE_OWN_AND_NEED_YOU_TO_PRINT = "I have my own barcodes and I need you to print them",
  NEED_YOU_TO_GENERATE_AND_PRINT = "I need you to generate and print all my barcodes",
}

export enum BarcodeCollectionOrCourier {
  COLLECT_FROM_CRAFT_X_PO = "I will collect my barcodes from the Craft X-Po market",
  COLLECT_FROM_SANS_SOUCI = "I will collect my barcodes from Sans Souci",
  COURIER = "I would like my barcodes couriered to me at my own expense",
}

export enum ProductCategory {
  ARTS_AND_CRAFTS = "Arts & crafts",
  BABY = "Baby",
  CHRISTMAS_DECOR = "Christmas decor",
  CLOTHING_AND_APPAREL = "Clothing & apparel",
  FOOD = "Food",
  GARDEN = "Garden",
  GIFTS_AND_STATIONERY = "Gifts & stationery",
  HEALTH_AND_BEAUTY = "Health & beauty",
  HOME_AND_DECOR = "Home & decor",
  JEWELLERY = "Jewellery",
  OTHER = "Other",
  PETS = "Pets",
  TOYS_AND_HOBBIES = "Toys & hobbies",
}

export enum BankAccountType {
  CHEQUE = "Cheque",
  SAVINGS = "Savings",
}

export type PostalAddress = {
  line1: string;
  line2: string | null;
  suburb: string;
  city: string;
  province: Province;
  postal_code: string;
};

export type BankAccount = {
  bank: Bank;
  account_holder_name: string;
  account_number: string;
  account_type: BankAccountType;
};

export type MarketExperience = {
  number_of_full_tables: number;
  number_of_half_tables: number;
  number_of_full_floor_spaces: number;
  number_of_half_floor_spaces: number;
  requires_electricity: boolean;
  barcode_numbers_and_printing: string;
  barcode_collection_or_courier: string;
  product_category: string;
};

export type Application = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile_number: string;
  postal_address: PostalAddress;
  bank_account: BankAccount;
  market_experience: MarketExperience;
  status: string;
};

export type Product = {
  id: string;
  name: string;
  price: string;
  barcode: string;
  labels: number | string;
  status: ProductStatus;
};
