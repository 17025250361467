import React from "react";
import { Col, Form } from "react-bootstrap";

type Props = {
  controlId?: string;
  children: React.ReactNode;
  [x: string]: any;
};

function FormGroup({ controlId, children, ...props }: Props) {
  return (
    <Form.Group as={Col} md="12" {...props} controlId={controlId}>
      {children}
    </Form.Group>
  );
}

export default FormGroup;
