import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../logo.png";
import Loader from "./Loader";
import { Toaster } from "react-hot-toast";
import "./Layout.css";

type Props = {
  title: string;
  children?: React.ReactNode;
};

function Layout({ title, children }: Props) {
  return (
    <Loader>
      <Toaster />
      <Container fluid className="mb-4">
        <Row className="header-row p-2">
          <Col className="text-center">
            <img
              src={Logo}
              className="img-fluid mt-4 mb-4"
              style={{ maxWidth: "200px" }}
            />
          </Col>
        </Row>
        <Row className="page-title-row p-4">
          <Col className="text-center">
            <h1>{title}</h1>
          </Col>
        </Row>
      </Container>
      <Container className="mt-2 mb-4">
        <Row>
          <Col>{children}</Col>
        </Row>
      </Container>
    </Loader>
  );
}

export default Layout;
