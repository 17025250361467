import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Product } from "../../types";

type Props = {
  show?: boolean;
  product: Product | null;
  onClickCancel?: () => void;
  onClickDelete?: () => void;
};

function RemoveProductModal({
  show = true,
  product,
  onClickCancel: onClickCancelCallback,
  onClickDelete: onClickDeleteCallback,
}: Props) {
  const onClickCancel = () => {
    if (onClickCancelCallback) {
      onClickCancelCallback();
    }
  };

  const onClickDelete = () => {
    if (onClickDeleteCallback) {
      onClickDeleteCallback();
    }
  };

  return (
    <Modal show={show} onHide={onClickCancel}>
      <Modal.Body>
        <p>
          Are you sure you'd like to delete the product{" "}
          <strong>{product?.name}</strong>
          <br />
          <br />
          <strong>Please note that this action cannot be undone.</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={onClickCancel}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onClickDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RemoveProductModal;
